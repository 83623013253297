import React, {
  useState,
  useEffect,
  useMemo,
} from "react";
import CLink from "../../cLink";
// import tw from "twin.macro";
import * as s from "./styles.module.css";
import Logo from "../logo";
import { LANGS } from "../../../langs/langHelpers";
import classnames from "classnames";
import useScroll from "../../../helpers/useScroll";




const HeaderOnlyLogo = ({ lang }) => {

  const { windowScrollY } = useScroll();
  const [isWhite, setIsWhite] = useState(true);

  const removeWhite = () => {
    setIsWhite(false);
  };
  const makeWhite = () => {
    setIsWhite(true);

  };

  useEffect(() => {
    if (isWhite && windowScrollY > 300) {
      removeWhite();
    } else if (!isWhite && windowScrollY <= 300) {
      makeWhite();
    }
  }, [windowScrollY]);

  return (
    <>
      <div className={classnames(s.container, s.isWhite)}>
        <div className={s.content}>
          <div className={s.logoArea}>

            <CLink className={s.logo} to={LANGS[lang].domain}>
              <Logo />
            </CLink>
          </div>
        </div>
      </div>
    </>

  );
};

export default HeaderOnlyLogo;
