// extracted by mini-css-extract-plugin
export var close = "styles-module--close--Cml2L";
export var container = "styles-module--container--3bjSa";
export var content = "styles-module--content--tuACj";
export var isMenuMobileOpen = "styles-module--isMenuMobileOpen--ZP78T";
export var isWhite = "styles-module--isWhite--uHi8P";
export var langArea = "styles-module--langArea--wJT-a";
export var langs = "styles-module--langs--XQtr-";
export var logo = "styles-module--logo--Y0aJn";
export var logoArea = "styles-module--logoArea--AOk4X";
export var sloganArea = "styles-module--sloganArea--Ug99Q";