const t = {
  menu: {
    home: "Accueil",
  },
  address: {
    a1: "CO - 3103 Km. 10 - 14420 - Villafranca - Córdoba (España)",
    a2: "T: <a href=\"tel:0034957191200\">0034 957 19 12 00</a>",
    a3: "Export: <a href=\"tel:0034957191213\">0034 957 191 213</a>",
  },
  forms: {
    ask: "Demandez-nous",
    yourInfo: "Vos coordonnées :",
    name: "Votre nom",
    loadedWrong1: "Il y a eu un problème pour envoyer le formulaire.",
    loadedWrong2:
      "Veuillez nous contacter à travers l'email info@obcocinas.es. Nous nous excusons pour tout désagrément.",
    loadedOk1: "Parfait! Message reçu!",
    loadedOk2: "Nous vous répondrons sous peu.",
    select: {
      question: "Comment pouvons-nous vous aider :",
      answer1:
        "Je souhaiterais connaître le point de vente le plus proche de chez moi.",
      answer2: `Je souhaiterais qu'un de vos représentants commerciaux me contacte.`,
      answer3:
        "Je souhaite faire une demande de renseignements ou vous envoyer un message.",
      message: "Votre demande ou message",
    },
    email: "Votre email",
    country: "Votre pays",
    nameOptional: "Votre nom (facultatif)",
    phone: "Votre portable",
    cp: "Votre code postal",
    legalText: `J'ai lu, compris et accepte la politique de confidentialité, et j'autorise Muebles OB S.A. à m'envoyer des communications commerciales électroniques personnalisées.`,
    send: "Envoyer",
    sending: "Envoi en cours…",
  },
  contact: {
    text1: "Nous vous disons quel est le distributeur le plus proche :",
  },
  cookies: {
    c1: `Ce site web utilise ses propres cookies et ceux de tiers pour le bon fonctionnement et l'affichage du site web par l'utilisateur. Vous pouvez modifier les paramètres ou obtenir de plus amples informations en consultant `,
    c2: `notre politique en matière de cookies`,
    accept: `Accepter`,
    reject: `Continuer sans accepter`,
    config: `Configurar las cookies del sitio`,
    stats: `Statistiques`,
    marketing: 'Marketing',
    required: 'Required',

  },
  play: "Play",
  dondeComprar: {
    texto1: `Chez chacun de nos distributeurs, vous aurez à votre disposition une équipe expérimentée qui vous fournira un soutien et des conseils techniques afin que le projet s'adapte à votre style et réponde aux besoins et exigences de votre projet. Vous pouvez remplir et nous envoyer ce formulaire, nous vous répondrons dans les meilleurs délais. Si vous préférez, vous pouvez également nous contacter par email à l'adresse`,
    texto2b: `info@obcocinas.es`,
  },
  footer: {
    social: "Vous pouvez aussi nous contacter à travers :",
  },
  private: {
    login: {
      text1: "Bienvenue dans l'espace privé d’obcocinas.fr",
      text2a: `Il vous permet de suivre vos commandes et d'accéder à notre programme de devis personnalisé. Si vous n'avez pas encore accès, vous pouvez nous contacter par email à`,
      text2b: "info@obcocinas.es",
      username: "Nom d'utilisateur",
      password: "Mot de passe",
      enter: "Entrer",
    },
    lostPass: {
      text1: `Saisissez l'adresse e-mail avec laquelle vous vous êtes inscrit et vous recevrez un e-mail avec un nouveau mot de passe.`,
      email: `Email`,
      volverInicio: `Retour à l'accueil de l'espace privé`,
      resetPass: `Réinitialiser le mot de passe`,
    },
    appBudget1: "Programme de **devis**",
    appBudget2: "(Site externe)",
    hi: "Bonjour",
    logout: "Déconnexion",
    searchByRef: "Recherchez à partir de la référence",
    searchByClient: "Recherche à partir du client",
    loadingData: "Chargement des données mises à jour...",
    noResult: "Aucune commande dans la base de données.",
    table: {
      item: "Commande",
      client: "Client",
      date: "Date",
      estado: "État",
      ref: "Réf:",
      nModules: "Nº modules:",
      clientNumber: "Nº de client:",
      distribution: "Distribution prévue:",
      delivery: "Livraison prévue:",
    },
  },
  others: "Autres...",
  estadoPedidos: [
    "EN ATTENTE DE FABRICATION",
    "EN FABRIQUE",
    "CHARGEMENT",
    "TRANSPORT - LIVRAISON",
  ],
  countries: [
    "Espagne",
    "France",
    "Portugal",
    "Floride",
    "Mexique",
    "République dominicaine",
    "Costa Rica",
    "Colombie",
    "Belgique",
    "Suisse",
    "Andorre",
    "Maroc",
    "Oman",
    "Sénégal",
  ],
  blog:{
    readMore: 'Lire plus',
    sharePost: `Partager l'article dans:`,
    category: 'Catégorie:'
  },
  projects: {
    model: "Modèle",
    by: "Par",
    acabado: "Finition",
    color: "Couleur",
    distribucion: "Disposition",
  },
  stores: {
    pedirUnaCita: "Prendre rendez-vous",
    pideCita: "Prendre rendez-vous",
    contactar: "Contacter",
  },
  landings: {
    hero: {
      cta1: "Infórmate ahora",
      cta2: "Pide una cita",
    },
  },
}

export default t
