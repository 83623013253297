import React, { createContext, useCallback, useState } from "react"
import { getTranslationObj } from "../langs/langHelpers"

export const GeneralContext = createContext()
const GeneralContextProvider = ({ lang, children, pageRuta, pageTemplate }) => {
  const t = getTranslationObj(lang)
  const [isMenuVisible, setIsMenuVisible] = useState(false)
  const [loading, setLoading] = useState({
    loadingState: "none", //loading, loaded
    textoLoading1: "",
    textoLoading2: "",
    textoLoaded1: "",
    textoLoaded2: "",
  })
  const updateLoading = props => {
    setLoading({
      loadingState: props.loadingState
        ? props.loadingState
        : loading.loadingState,
      textoLoading1: props.textoLoading1
        ? props.textoLoading1
        : loading.textoLoading1,
      textoLoading2: props.textoLoading2
        ? props.textoLoading2
        : loading.textoLoading2,
      textoLoaded1: props.textoLoaded1
        ? props.textoLoaded1
        : loading.textoLoaded1,
      textoLoaded2: props.textoLoaded2
        ? props.textoLoaded2
        : loading.textoLoaded2,
    })
  }

  const [forceShowCookies, setForceShowCookies] = useState(false)

  // CTA Modal control
  const [ctaModalShow, setCtaModalShow] = useState(null)

  const registerCtaModalShow = useCallback(newCtaModalShow => {
    setCtaModalShow(() => newCtaModalShow)
  }, [])


  // const [ pageRuta, setPageRuta] = useState('')
  // const [ pageTemplate, setPageTemplate] = useState('')

  return (
    <GeneralContext.Provider
      value={{
        isMenuVisible: isMenuVisible,
        setIsMenuVisible: setIsMenuVisible,

        // anyData: anyData,
        // updateAnyData: updateAnyData,

        loading: loading,

        updateLoading: updateLoading,

        t: t,

        // langCode: data.site.siteMetadata.langCode,
        lang: lang,
        forceShowCookies: forceShowCookies,
        setForceShowCookies: setForceShowCookies,

        // cta: data.site.siteMetadata.cta,

        ctaModalShow: ctaModalShow,
        registerCtaModalShow: registerCtaModalShow,

        pageRuta: pageRuta,
        pageTemplate: pageTemplate,
      }}
    >
      {children}
    </GeneralContext.Provider>
  )
}
export default GeneralContextProvider
