const t = {
  menu: {
    home: "Inicio",
  },
  address: {
    a1: "CO - 3103 Km. 10 - 14420 - Villafranca - Córdoba (España)",
    a2: "T: <a href=\"tel:957191200\">957 19 12 00</a>",
    a3: "Export: <a href=\"tel:0034957191213\">0034 957 191 213</a>",
  },
  forms: {
    ask: "Pregúntanos",
    yourInfo: "Tus datos:",
    name: "Tu nombre",
    loadedWrong1: "Ha habido un problema el enviar el formulario.",
    loadedWrong2:
      "Por favor, ponte en contacto con nosotros a través de info@obcocinas.es. Disculpa las molestias.",
    loadedOk1: "¡Bien! Mensaje recibido",
    loadedOk2: "Te contestaremos en breve.",
    select: {
      question: "En qué podemos ayudarte:",
      answer1: "Deseo conocer el punto de venta más próximo a mi localización.",
      answer2: `Deseo
      que uno de vuestros agentes comerciales contacte conmigo.`,
      answer3: "Quiero haceros una consulta o mandaros un mensaje.",
      message: "Tu consulta o mensaje",
    },
    email: "Tu email",
    country: "Tu país",
    nameOptional: "Tu nombre (opcional)",
    phone: "Tu móvil",
    cp: "Tu código postal",
    legalText: `He leído, entiendo y acepto la política de privacidad, y autorizo el envío de
    comunicaciones comerciales electrónicas
    personalizadas de Muebles OB S.A.`,
    send: "Enviar",
    sending: "Enviando...",
  },
  contact: {
    text1: "Te decimos cual es tu distribuidor más cercano:",
  },
  cookies: {
    c1: `Este sitio web utiliza cookies propias y de terceros para el correcto funcionamiento y visualización del sitio web por parte del usuario. Puede cambiar la configuración u obtener más información visitando nuestra`,
    c2: `Política de Cookies`,
    accept: `Aceptar`,
    reject: `Rechazar`,
    config: `Configurar las cookies del sitio`,
    stats: `Estadísticas`,
    marketing: 'Marketing',
    required: 'Necesarias',
  },
  play: "Reproducir",
  dondeComprar: {
    texto1: `En cada uno de nuestros distribuidores tendrás a tu disposición un
    equipo experimentado que te proporcionará apoyo y asesoramiento
    técnico para que el proyecto se ajuste a tú estilo y responda a las
    necesidades y requisitos planteados en tu proyecto.`,
    texto2a: `Puedes rellenar y enviarnos este formulario, te contestaremos en
    breve. Si lo prefieres, puedes también ponerte en contacto con
    nosotros a través del email`,
    texto2b: `info@obcocinas.es`,
  },
  footer: {
    social: "También puedes contactar con nosotros vía:",
  },
  private: {
    login: {
      text1: "Bienvenido al área privada de obcocinas.es",
      text2a: `Permite el seguimiento de tus pedidos y acceder a nuestro programa
      de presupuestos personalizados. Si no tienes acceso aún, puedes
      ponerte en contacto con nosotros a través del email`,
      text2b: "info@obcocinas.es",
      username: "Nombre de usuario",
      password: "Contraseña",
      enter: "Entrar",
    },
    lostPass: {
      text1: `Escribe la dirección de correo electrónico con la que te diste de
    alta y recibirás un mensaje con la nueva contraseña.`,
      email: `Correo electrónico`,
      volverInicio: `Volver inicio del área privada.`,
      resetPass: `Reiniciar contraseña`,
    },
    appBudget1: "Programa de **presupuestos**",
    appBudget2: "(Página externa)",
    hi: "Hola",
    logout: "Cerrar sesión",
    searchByRef: "Busca por referencia",
    searchByClient: "Busca por cliente",
    loadingData: "Cargando datos actualizados...",
    noResult: "Ningún pedido en la base de datos.",
    table: {
      item: "Pedido",
      client: "Cliente",
      date: "Fecha",
      estado: "Estado",
      ref: "Ref:",
      nModules: "Nº módulos:",
      clientNumber: "Nº cliente:",
      distribution: "Previsión reparto:",
      delivery: "Previsión entrega:",
    },
  },
  others: "Otros...",
  estadoPedidos: [
    "PENDIENTE FABRICACIÓN",
    "FABRICACIÓN",
    "EN CARGA",
    "EN RUTA - ENTREGADO",
  ],
  countries: [
    "España",
    "Francia",
    "Portugal",
    "Florida",
    "México",
    "República Dominicana",
    "Costa Rica",
    "Colombia",
    "Bélgica",
    "Suiza",
    "Andorra",
    "Marruecos",
    "Omán",
    "Senegal",
  ],
  blog:{
    readMore: 'Leer Más',
    sharePost: 'Compartir artículo en:',
    category: 'Categoría:'
  },
  projects: {
    model: "Modelo",
    by: "Por",
    acabado: "Acabado",
    color: "Color",
    distribucion: "Distribucion",
  },
  stores: {
    pedirUnaCita: "Pedir una cita",
    pideCita: "Pide cita",
    contactar: "Contactar",
  },
  landings: {
    hero: {
      cta1: "Infórmate ahora",
      cta2: "Pide una cita",
    },
  },

}

export default t
